@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaThin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('assets/fonts/LufgaRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaSemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lufga';
  src: url('./assets/fonts/LufgaBlack.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  width: 100vw;
  width: 100svw;
  height: 100vh;
  height: 100svh;
  font-family: 'Lufga', sans-serif;
  font-weight: 400; /* or bold, 800 for extrabold, etc. */
  font-style: normal; /* or italic, as needed */
  color: #333;
}

#root, .App {
  width: 100%;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.timeline-base {
  stroke: #444;
  stroke-width: 0.2;
}
.circle-category {
  /* fill: #272d2d; */
  transition: fill-opacity 0.2s, r 0.5s;
  -webkit-transition: fill-opacity 0.2s, r 0.5s;
}
.circle-hovered {
  fill-opacity: 0.2;
}
.circle-clicked {
  fill: #fff;
  transition: fill 1s, r 0.9s;
  -webkit-transition: fill 1s, r 0.9s;
}


.yearTimelineThumb:focus-within {
  outline: none;
  border: none;
}